import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import BaseSelect from '@material-ui/core/Select';
import React from 'react';
import styled from 'styled-components';
import { purple, text } from '../style/colors';

export default ({ label, helperText, children, ...props }) => {
  return (
    <StyledFormControl>
      <InputLabel shrink>{label}</InputLabel>
      <Select {...props}>{children}</Select>
      <FormHelperText>{helperText}</FormHelperText>
    </StyledFormControl>
  );
};

const Select = styled(BaseSelect)`
  && {
    color: ${text.primary};
    &:after {
      background-color: ${purple[500]};
    }
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    color: ${text.primary};
    label {
      color: ${text.primary};
    }
  }
`;
