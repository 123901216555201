// @flow
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import PhoneIcon from "@material-ui/icons/Phone";
import Send from "@material-ui/icons/Send";
import { graphql, navigate } from "gatsby";
import * as React from "react";
import styled from "styled-components";
import ArrowLink from "../components/ArrowLink";
import Button from "../components/button";
import Footer from "../components/Footer";
import Header from "../components/header";
import Page from "../components/Page";
import Section from "../components/Section";
import Select from "../components/Select";
import TextField from "../components/TextField";
import Logo from "../icons/Logo";
import { LARGE_BREAKPOINT, query, XL_BREAKPOINT } from "../style";
import { telUrl } from "../utils/constants";

import type { ImageSharp, Provider } from "../types";

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

type Props = {
  data: {
    allContentfulProvider: {
      edges: Array<{ node: Provider }>
    }
  }
};
type FormType = {
  fullName: { value: string, error: ?string, pristine: boolean },
  phone: { value: string, error: ?string, pristine: boolean },
  email: { value: string, error: ?string, pristine: boolean },
  reason: { value: string, error: ?string, pristine: boolean },
  provider: { value: string, error: ?string, pristine: boolean }
};

type State = {
  form: FormType,
  status: "FILLING" | "ERROR" | "PENDING" | "FAILED" | "SUCCESS"
};

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

const initialForm: FormType = {
  fullName: { value: "", error: null, pristine: true },
  phone: { value: "", error: null, pristine: true },
  email: { value: "", error: null, pristine: true },
  reason: { value: "", error: null, pristine: true },
  provider: { value: "", error: null, pristine: true }
};

export default class RequestAppointment extends React.Component<Props, State> {
  state: State = {
    form: initialForm,
    status: "FILLING"
  };

  handleChange = (e: SyntheticInputEvent<>) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState(({ form, ...state }) => {
      const old = form[name];
      return {
        ...state,
        form: {
          ...form,
          [name]: {
            pristine: true,
            value,
            error: this.validate(name, value)
          }
        }
      };
    });
  };

  handleBlur = (e: SyntheticInputEvent<>) => {
    const name = e.target.id;
    const value = e.target.value;
    this.setState(({ form, ...state }) => {
      const old = form[name];
      return {
        ...state,
        form: {
          ...form,
          [name]: {
            ...old,
            pristine: false,
            error: this.validate(name, value)
          }
        }
      };
    });
  };

  validate = (name: string, value: string) => {
    switch (name) {
      case "email":
        if (!EMAIL_REGEX.test(value))
          return "This appears to be an invalid email address";
        return null;
      case "phone":
        if (!value || value.replace(/\D/g, "").length !== 10)
          return "Phone number must be 10 digits long";
        return null;
      case "fullName":
        if (!value || value.length < 3) return "Please enter your full name";
        return null;
      case "reason":
        if (!value || value.length < 5)
          return "Please tell us your reason for requesting an appointment";
        return null;
      case "provider":
        return null;
      default:
        return null;
    }
  };

  handleSubmit = () => {
    // check that they are all valid
    let err = false;
    const { form } = this.state;
    const nextForm = initialForm;
    Object.keys(form).forEach(key => {
      const name = key;
      let value: string =
        typeof form[key].value === "string" ? form[key].value : "";
      const error = this.validate(key, value);
      err = err || error;
      nextForm[name] = {
        value,
        error,
        pristine: false
      };

      this.setState({ form: nextForm });
    });

    if (err) return this.setState({ status: "ERROR" });

    // actually submit this thing.
    this.setState({ status: "PENDING" });
    const formData = this.extractFormdata();
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "request-appointment", ...formData })
    })
      .then(res => {
        if (res.ok) {
          this.setState({ status: "SUCCESS" });
        } else {
          this.setState({
            status: "FAILED"
          });
        }
      })
      .catch(error => {
        alert(error);
        this.setState({ status: "FAILED" });
      });
  };

  extractFormdata = () => {
    return Object.keys(this.state.form).reduce((acc, k) => {
      acc[k] = this.state.form[k].value;
      return acc;
    }, {});
  };

  render() {
    const physicians: Array<{ node: Provider }> = this.props.data
      .allContentfulProvider.edges;
    const { form, status } = this.state;
    const err = Object.keys(form).reduce((prev, key) => {
      return (form[key].error && !form[key].pristine) || prev;
    }, false);

    const color = "#444F5A";

    return (
      <Page
        style={{ background: "linear-gradient(90deg,#777bf8,#68d4f8 200%)" }}
        title="Request an appointment"
      >
        <Header
          inverse
          thin
          centered
          // short
          cta={null}
          simpleBackground="#ECF5FD"
          title=""
          subtitle=""
        />
        <HeaderSection
          style={{ zIndex: 0 }}
          backgroundStyle={{
            top: "-200%",
            bottom: -110,
            height: "initial"
          }}
          color="#edf5fd"
        >
          <Title
            style={{
              color,
              textAlign: "center",
              alignSelf: "stretch"
            }}
          >
            Contact our Patient Coordinator
          </Title>
          <Subtitle
            style={{
              color,
              textAlign: "center",
              alignSelf: "stretch"
            }}
          >
            Please fill out the form below and our Patient Coordinator will be
            in touch to schedule you with the right provider. All information
            you provide is private and secure.
          </Subtitle>
        </HeaderSection>
        <StyledSection color="rgba(0,0,0,0)">
          <form
            name="request-appointment"
            method="post"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="form-name" value="request-appointment" />
            <Card raised style={{ position: "relative" }}>
              <SuccessMessage success={status == "SUCCESS"}>
                <StyledLogo color="#444F5A" />
                <SuccessHeader>
                  Thank you for trusting us with your care.
                </SuccessHeader>
                <SuccessBody>
                  A member of our patient care team will contact you within 24
                  hours or the next business day, if a weekend, to schedule your
                  appointment.
                </SuccessBody>
                <ArrowLink to="/" style={{ alignSelf: "flex-end" }}>
                  Go back to home
                </ArrowLink>
              </SuccessMessage>
              <CardContent
                style={{
                  visibility: status == "SUCCESS" ? "hidden" : "visible"
                }}
              >
                <TextField
                  name="fullName"
                  id="fullName"
                  label="Full Name"
                  placeholder="Jane Doe"
                  value={form.fullName.value}
                  margin="normal"
                  fullWidth
                  required
                  onChange={this.handleChange}
                  helperText={!form.fullName.pristine && form.fullName.error}
                  error={!form.fullName.pristine && !!form.fullName.error}
                  InputLabelProps={{ shrink: true }}
                  onBlur={this.handleBlur}
                />
                <TextField
                  name="email"
                  id="email"
                  label="Email"
                  type="email"
                  placeholder="jane@example.com"
                  value={form.email.value}
                  margin="normal"
                  fullWidth
                  required
                  onChange={this.handleChange}
                  helperText={!form.email.pristine && form.email.error}
                  error={!form.email.pristine && !!form.email.error}
                  InputLabelProps={{ shrink: true }}
                  onBlur={this.handleBlur}
                />
                <TextField
                  name="phone"
                  id="phone"
                  label="Phone Number"
                  placeholder="(208) 222-2222"
                  value={form.phone.value}
                  margin="normal"
                  fullWidth
                  type="tel"
                  required
                  onChange={this.handleChange}
                  helperText={!form.phone.pristine && form.phone.error}
                  error={!form.phone.pristine && !!form.phone.error}
                  InputLabelProps={{ shrink: true }}
                  onBlur={this.handleBlur}
                />
                <Select
                  name="provider"
                  label="Requested Provider"
                  helperText="You only need to enter this if you have a specific provider in mind"
                  native
                  id="provider"
                  value={form.provider.value ? form.provider.value : ""}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                >
                  <option value="None" />
                  <optgroup label="Spine Surgeons">
                    {physicians
                      .filter(({ node }) => node.type == "Spine Surgeon")
                      .map(({ node }) => (
                        <option key={node.name} value={node.name}>
                          {node.name}
                        </option>
                      ))}
                  </optgroup>
                  <optgroup label="Physiatrists">
                    {physicians
                      .filter(({ node }) => node.type == "Physiatrist")
                      .map(({ node }) => (
                        <option key={node.name} value={node.name}>
                          {node.name}
                        </option>
                      ))}
                  </optgroup>
                  <optgroup label="NPs and PAs">
                    {physicians
                      .filter(
                        ({ node }) =>
                          node.type == "Nurse Practitioner" ||
                          node.type == "Physician Assistant"
                      )
                      .map(({ node }) => (
                        <option key={node.name} value={node.name}>
                          {node.name},{" "}
                          {node.type == "Nurse Practitioner" && "NP"}
                          {node.type == "Physician Assistant" && "PA"}
                        </option>
                      ))}
                  </optgroup>
                  <optgroup label="Physical Therapists">
                    {physicians
                      .filter(({ node }) => node.type == "Physical Therapist")
                      .map(({ node }) => (
                        <option key={node.name} value={node.name}>
                          {node.name}, DPT
                        </option>
                      ))}
                  </optgroup>
                </Select>

                <TextField
                  InputLabelProps={{ shrink: true }}
                  id="reason"
                  name="reason"
                  label="Reason for appointment"
                  value={form.reason.value}
                  margin="normal"
                  fullWidth
                  rows="4"
                  required
                  multiline
                  onChange={this.handleChange}
                  helperText={!form.reason.pristine && form.reason.error}
                  error={!form.reason.pristine && !!form.reason.error}
                  onBlur={this.handleBlur}
                />
              </CardContent>
              <CardActions
                style={{
                  padding: 16,
                  justifyContent: "flex-end",
                  height: "initial",
                  visibility: status == "SUCCESS" ? "hidden" : "visible"
                }}
              >
                <Button
                  color="secondary"
                  disabled={err || status == "PENDING" || status == "SUCCESS"}
                  onClick={this.handleSubmit}
                >
                  Request Appointment{" "}
                  <Send style={{ marginLeft: 8, rotate: "45deg" }} />
                </Button>
              </CardActions>
              {/* </form> */}
            </Card>
          </form>
          <CallUs>
            If you'd rather, you can always give us a call instead:
          </CallUs>
          <DivButton
            onClick={() =>
              window && window.innerWidth < 600
                ? (window.location.href = telUrl)
                : navigate("/contact")
            }
          >
            <StyledPhoneIcon /> Call us
          </DivButton>
        </StyledSection>
        <Footer />
      </Page>
    );
  }
}

const StyledLogo = styled(Logo)`
  && {
    height: 90px;
    width: auto;
    padding: 0px;
    margin-top: 20px;
  }
`;

const StyledPhoneIcon = styled(PhoneIcon)`
  && {
    height: 20px;
    margin-right: 10px;
  }
`;

const DivButton = styled(Button)`
  && {
    margin: 10px;
    text-align: center;
    margin-left: 0px;
  }
`;

const CallUs = styled.h2`
  && {
    margin-top: 50px;
    margin-bottom: 0.5rem;
    font-weight: 300;
    letter-spacing: 0.5px;
    color: white;
  }
`;

const SuccessMessage = styled.div`
  && {
    position: absolute;
    opacity: ${({ success }) => (success ? 1 : 0)};
    padding: 16px;
    transition: all 0.5s ease-in-out;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100%;
  }
`;

const SuccessHeader = styled.h1`
  && {
    text-align: center;
    ${query(LARGE_BREAKPOINT)`
      font-size: 40px;
    `};
  }
`;

const SuccessBody = styled.p`
  && {
    color: #79a6eb;
    font-size: 22px;
    text-align: center;
    line-height: 1.3;
    flex: 1;
    max-width: 100%;
  }
`;

const HeaderSection = styled(Section)`
  && {
    padding: 20px 20px 0px 20px;
    ${query(LARGE_BREAKPOINT)`
      padding: 60px 20px 0px 20px;
    `};
    max-width: 870px;
  }
`;

const Title = styled.h1`
  && {
    color: white;
    ${query(LARGE_BREAKPOINT)`
    font-size: 40px;
    margin-bottom: 50px;
  `};
  }
`;

const Subtitle = styled.p`
  && {
    color: rgba(255, 255, 255, 0.85);
    ${query(LARGE_BREAKPOINT)`
    font-size: 20px;
    margin-bottom: 70px;
  `};
    ${query(XL_BREAKPOINT)`
    font-size: 22px;
  `};
  }
`;

const StyledSection = styled(Section)`
  && {
    padding: 30px 20px 100px 20px;
  }
`;

/**
 * FIELDS:
 *    name
 *    email
 *    phone
 *    reason for appointment
 *    requested provider (drop down)
 *
 * VALIDATIONS
 *    not empty
 *    valid email
 *    valid phone number
 */

export const pageQuery = graphql`
  query ProviderSelect {
    allContentfulProvider {
      edges {
        node {
          name
          type
        }
      }
    }
  }
`;
